<template>
  <div
    class="relative top-0 left-0 flex flex-col justify-between w-full h-auto max-w-full"
  >
    <span
      v-if="showInitialTabIndexEl"
      ref="tpl_initialTabIndex"
      @focus="blur()"
      @blur="showInitialTabIndexEl = false"
      tabindex="1"
      class="sr-only invisible"
      >tab-index-o</span
    >

    <a
      class="fixed top-0 left-[50%] z-50 bg-black/70 text-white p-4 text-body whitespace-nowrap w-min max-w-min -translate-x-1/2 opacity-0 pointer-events-none scale-90 focus:scale-100 focus:outline-none focus:shadow-none focus:pointer-events-auto focus:opacity-100 transition-[opacity,transform] duration-300 hover:underline rounded-b-lg origin-top"
      href="#main-content"
      :title="$t.labels.skip_to_main_content"
      :aria-label="$t.labels.skip_to_main_content"
      @click.prevent.stop="scrollToMain($event)"
      >{{ $t.labels.skip_to_main_content }}</a
    >

    <!-- DEFAULT LAYOUT -->
    <nuxt-layout @onEnter="onEnter" />
    <!-- end DEFAULT LAYOUT -->

    <!-- APP MODAL SPINNER -->
    <modal-spinner :is-open="appInert" class="z-3" />
    <!-- end APP MODAL SPINNER -->

    <!-- APP MODAL ACCOUNT ACTIVATED -->
    <template v-if="accountActivated">
      <modal-account-activated
        :is-open="accountActivated"
        @close="accountActivated = undefined"
        :email="route.query.email"
      />
    </template>
    <!-- end APP MODAL ACCOUNT ACTIVATED -->
  </div>

  <!-- INITIAL CURTAIN -->
  <transition-fade v-if="showInitialCurtain !== 'skip'" :use-appear="false">
    <div
      v-if="showInitialCurtain"
      class="fixed z-[9999] inset-0 w-screen h-screen bg-skin-base"
    ></div>
  </transition-fade>
  <!-- end INITIAL CURTAIN -->

  <!-- IUBENDA PREFERENCES REFERENCE BTN -->
  <button
    inert
    class="!hidden iubenda-cs-preferences-link"
    v-html="$t.labels.review_cookie_settings"
  ></button>
  <!-- end IUBENDA PREFERENCES REFERENCE BTN -->
</template>

<script setup>
import { useRootStore } from "@/stores/root";
import { useGeolistStore } from "@/stores/geolist";
import { useUserStore } from "@/stores/user";
import Lenis from "lenis";

// http://localhost:3000/?redirect=products/find-product&token=61970-719-1455-839238&email=matteo.macellari@gmail.com&account-activated=true

const route = useRoute();
const router = useRouter();
const rootStore = useRootStore();
const geolistStore = useGeolistStore();
const { appInert, setAppInert } = useGlobals();
const { data } = await useFetch(`/api/locales?lng=${route.params.locale}`);

await rootStore.loadUI();
await geolistStore.load();

const accountActivated = useCookie("account-activated");
if (route.query["account-activated"] === "true") {
  const { data } = await useFetch(
    `/api/confirm-user?email=${route.query.email}&token=${route.query.token}&lng=${route.params.locale}`
  );
  console.log("Account activated", data.value);

  accountActivated.value = data.value.status === "success";
}

const tpl_initialTabIndex = templateRef("tpl_initialTabIndex");
const showInitialCurtain = ref(true);
const showInitialTabIndexEl = ref(false);

const { isMobile } = useDevice();

const nuxtApp = useNuxtApp();
nuxtApp.provide("t", data.value);

const { getConfigs } = useIubenda();

let lenis;

router.beforeEach((to, from) => {
  if (to.path !== from.path) {
    setAppInert(true);
  }
});

router.afterEach((to, from) => {
  // sometimes the apps gets stuck in inert mode
  // this is a safety net to prevent that
  setTimeout(() => {
    setAppInert(false);
    // remove the inert attribute from the body
    document.body.removeAttribute("inert");
  }, 2000);

  rootStore.setPrevRoute({
    name: from.name,
    fullPath: from.fullPath,
    hash: from.hash,
    href: from.href,
    path: from.path,
    query: from.query,
    params: from.params,
  });
});

watch(showInitialTabIndexEl, (newVal, oldVal) => {
  if (newVal) {
    useSafeMountedEl(
      tpl_initialTabIndex,
      () => {
        tpl_initialTabIndex.value.focus();
        tpl_initialTabIndex.value.blur();
      },
      false
    );
  }
});

provideHeadlessUseId(() => useId());

useHead({
  htmlAttrs: {
    "data-skin": "sand",
    lang: route.params.locale,
  },
  bodyAttrs: {
    inert: computed(() => appInert.value),
  },
  script: [
    {
      src: "https://cdn.roomvo.com/static/scripts/b2b/mirageit.js",
      type: "text/javascript",
      async: true,
    },
    {
      innerHTML: getConfigs(),
    },
    {
      type: "text/javascript",
      src: `//cs.iubenda.com/sync/2298250.js`,
    },
    {
      src: "//cdn.iubenda.com/cs/gpp/stub.js",
      type: "text/javascript",
    },
    {
      src: "https://cdn.iubenda.com/cs/iubenda_cs.js",
      type: "text/javascript",
      // charset: "UTF-8",
      async: true,
    },
    {
      type: "application/ld+json",
      innerHTML: `{ "@context": "https://schema.org", "@type": "Organization", "url": "https://www.mirage.it", "logo": "https://www.mirage.it/logo.jpg" }`,
    },
  ],
});

const userStore = useUserStore();
if (!userStore.logged) {
  userStore
    .validate()
    .then(() => {})
    .catch((error) => {
      console.log("ERROR", error);
    });
}

const onEnter = () => {
  showInitialTabIndexEl.value = true;
};

onMounted(() => {
  lenis = new Lenis();
  nuxtApp.provide("lenis", lenis);

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  gsap.ticker.lagSmoothing(0);

  if (isMobile) {
    document.querySelector("html").classList.add("is-mobile");
  }

  if (sessionStorage.getItem("skip-intro") === "true") {
    showInitialCurtain.value = "skip";
  } else {
    showInitialCurtain.value = false;
    sessionStorage.setItem("skip-intro", true);
  }

  if (route.query["account-activated"] === "true") {
    const clearQuery = { ...route.query };
    delete clearQuery["account-activated"];
    delete clearQuery["token"];
    delete clearQuery["email"];
    router.replace({
      query: clearQuery,
    });
  }
});

onBeforeUnmount(() => {
  accountActivated.value = undefined;
});

function scrollToMain(event) {
  event.target.blur();
  lenis.scrollTo(0, {
    force: true,
    lock: true,
    offsetY: -1,
    duration: 0.4,
    easing: gsap.parseEase("power2.out"),
  });
}
</script>


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexlt0BO_45AKhYJG8y_45E36sEs_45yiMvsbWsQeO50gBcXQSC0Meta } from "/var/www/html/mirage/frontend/pages/index.vue?macro=true";
import { default as _404aBpK5Stf1X_UICSd_45ytVQrDjN_45Vbmg2waMvXsnvI1PYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/404.vue?macro=true";
import { default as indexDcD0wTkRK1lJxK1HmnQeDgWcME7QH6JGmk634akN_4560Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/index.vue?macro=true";
import { default as indexqP22sbXg_vrN_45CDxVxILLXn1iYgB3nIkl90AUKnRrPkMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-commercial-consultancy/index.vue?macro=true";
import { default as indexMMq29cY1cmSprBqD1OfAFGA2LN2XkdjaOcP9327fx9MMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-hr-images-request/index.vue?macro=true";
import { default as index4QP7_45ks76F0mwZC817v9uUpscdlW_BDRYXZD4uGAuYIMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-product-info-and-solutions/index.vue?macro=true";
import { default as indexXgim02cShJH3DkPmb57kBo9o1baubERyvZnI2DT1nkIMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-commercial-consultancy/index.vue?macro=true";
import { default as indexwdO_45aL9ZOdCmVfADdL1c10laQJIuU69SZQwrESln1g4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-product-info-and-solutions/index.vue?macro=true";
import { default as indexXerzkEUBTFKHXn15iUrtkivB0K05umdgruMHgIII1KgMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-after-sales-and-installation-technical-assistance/index.vue?macro=true";
import { default as index086cfcq_8aonp0efqQqL7nuanu8VGYlUTQx3QBLaKMIMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-after-sales-and-installation-technical-assistance/index.vue?macro=true";
import { default as supportrGNVsVjvRtbnsyF_45Hr7cRBCCKyiDATsGEuAw3eBzMwUMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support.vue?macro=true";
import { default as ease3f5MkdNTiYbTndVaDy3MlvkHATEerlz2HBxrzvnhoosMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/ease.vue?macro=true";
import { default as indexZuZf_45oAcNGGHlpDeissFGnyXCxfdeV1fc7nQ8wRh1QoMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/faq/index.vue?macro=true";
import { default as _91id_93CjAtecVzZm7U6L6kA1ucqlk3jGzBLnWXEdbbuSp1ZpIMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/news/[id].vue?macro=true";
import { default as indexHydsIn6wRjVBVL8QbuHhA_jrzmQjMpfqTwKqQo2FQtsMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/handbooks/index.vue?macro=true";
import { default as index2S_45xsxYOYTuHBXh_N_hKXHi_45Rh8mhtYkl_45Z80CyuG0EMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/monographs/index.vue?macro=true";
import { default as indexmW9Xd_45D_iXGijyVw8958hG_45unWKTitWKglUXAlli9usMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/collections/index.vue?macro=true";
import { default as indexL2fLAs8gING7BW21bVRFy_45jfuXcTQTCyKcp2TLyoxkcMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/certifications/index.vue?macro=true";
import { default as resourcesH7DrQf_gAbiKzICxwMc46xgxiW9P6ytzDaDyELqktYgMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources.vue?macro=true";
import { default as indexdW4SJpsv_I5GMxanBP_YTMrtnTYl2CM8vFeCAbeUyzIMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/form/index.vue?macro=true";
import { default as indexUZIzV6FfrecjePSdiB2nsQB_P4PczKw9jSiErfvrxrQMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/grid/index.vue?macro=true";
import { default as indexueMzy7TX0FcDj3xChLRn9UwNOiUUtmOoX4WOqegNLKkMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/text/index.vue?macro=true";
import { default as index6Vexg_45wsSRRkaSXcNCOyMp1H4BQQI1qcVwJR76qODlcMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/media/index.vue?macro=true";
import { default as indexFO_WGr5r3gfaooMnu5FnvxYR6uf_iR0DkIXgovACmYYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/divider/index.vue?macro=true";
import { default as indexnqW8Xi7ACWBa3fHBC3_454rDNyqi5ADFmlC3D3W_Q_45VVYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/toolbar/index.vue?macro=true";
import { default as indexPsDh8jHVEDbJy6j_72nApiWqcii_RR_sTOcp5qlTDHQMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/carousel/index.vue?macro=true";
import { default as index81kYBhvE80ukuaN_45dpLyu1pZfenLbHGTfNmDJ0YfgPwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/launcher/index.vue?macro=true";
import { default as block7sf_45UI_anSgGhafkG6ncoF4Rm5FdEjecaH5vMABNbAoMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block.vue?macro=true";
import { default as indexWCK6uOXJb0CGTNVJM8b8bLvjogR7pquTSH4E6GRt0Z4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/index.vue?macro=true";
import { default as surferKH3qjRwe2mmJUZt9GtsrEpr9J2O5_o_45N3tGw6gRKAhUMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/surfer.vue?macro=true";
import { default as indexwfzYmFFN3hExfzwB80rYOB_vNbo0nyy32qSRcL1EUjcMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/press/index.vue?macro=true";
import { default as _91id_93NnSM6Wq0RKowHYbb9Tid8s6pXilXqb5chKY3wgD9JUsMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/trends/[id].vue?macro=true";
import { default as geolisteDQE6Y9lSBXzzCqYrDp3Lp3Izcm8w1_MsxzBVthweSAMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/geolist.vue?macro=true";
import { default as indexV8CsTvtJcrZWxerpFgD2j7hNGd_45xspm93UZCS3umjQoMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/search/index.vue?macro=true";
import { default as indexgg8g4fcGdkaU0HBly181roiQzZdCwMeV1O_455iFEV7FMMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signin/index.vue?macro=true";
import { default as indexUBAUGbtYA3bjUvvTJPmke_45ah6uH9kzbSQaiMA0E3444Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/index.vue?macro=true";
import { default as index5ZPhV3kO0Bxp3pB6DEXXG_KGapVWMYa7L84UB7dV0cQMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/index.vue?macro=true";
import { default as composerKXC5hl9mytKdfGA9dKqK15A8jtsu_XWgcfzbPHxlEv8Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/composer.vue?macro=true";
import { default as _91id_93BiXhZT8A4gblYZ8BSGDbVrfF42k_45TMyRWlpZHB3ip6wMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/projects/[id].vue?macro=true";
import { default as indexvdcvVDauxW9VTl1xpsh95sittVwSqxCzc_45pzTibFI6sMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/contacts/index.vue?macro=true";
import { default as index6IDTHWMH1m0pbteHwYudDKrmUT16c1iU52jI_451jypMEMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile/info/index.vue?macro=true";
import { default as indexTwHPcCzCKvTwN2qysmZi_G2v_45_j6rEsxcd4MQw6_7msMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile/change-password/index.vue?macro=true";
import { default as profileMFnNXkKc8_45eEEJ5Elg6zEBHEOLT2MkBBZOeIW52hJoIMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile.vue?macro=true";
import { default as _91id_93g4rl0tU23S8jZUStu4Ckb9OxrHVEULmbLYUU_nGlVeQMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/moodboards/[id].vue?macro=true";
import { default as carousel_45pingIGVcKJIDNRamjx08_kRPNNAUwa3VfmVr2m57_1xQaYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/carousel-pin.vue?macro=true";
import { default as media_45optionsgmLmEuwDarCEgpsA2ARVad48Z6Sui5rOX8gyQM_4558pMMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/media-options.vue?macro=true";
import { default as _91id_93sWsxI55PJlKrpvm9bgAH1BlJPVLln0lF09kwleewlj8Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/design-stories/[id].vue?macro=true";
import { default as indexTFT_MwADQiq4kQ6ZaIflMdhsrzIbYc6P_AzAwGdn6c0Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/[id]/index.vue?macro=true";
import { default as indexIfewDkRlWFpUujL0Xv1FT1EoekKG1pWBiDPcgnWBumwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/store-locator/index.vue?macro=true";
import { default as indexJ3H2GBhl_45D1fye1I7kTRlotpgdssOwnGfGcqJ3_45UBp0Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories/news/index.vue?macro=true";
import { default as indexxpzqUlrYAErNC3U6OsuiczUbywcPrtEpJUTHN1Q70VwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories/trends/index.vue?macro=true";
import { default as indexSrGCou7IB1Ypxd39BXuahtj6pECStj3ZobIr1MDSfZsMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories/projects/index.vue?macro=true";
import { default as indexts_45E_Pep9MwlBWSrJFF1GUgEXKkYm2V4xZxVkf3xcQYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories/design-stories/index.vue?macro=true";
import { default as projects_45and_45storiesqktrwUtYxe5UVi5w4gsyrICBU8x5uazhHG5a4vPq3NsMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories.vue?macro=true";
import { default as indexJDMEaqw_RxwcQAioNwuYHCK_cu1WeDqQVIk_45dv47MQEMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/private/index.vue?macro=true";
import { default as indexQWk4q_45YHSCxHCSOPhHWTBCaIIdYk6A9iSJSvV5LKFpwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/favourites/index.vue?macro=true";
import { default as index5b2s_jJgQVw7X0C_2vkfupEc_A3_45mCHs1erhOi6W73IMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/hires-imgs/index.vue?macro=true";
import { default as indexQnxvIuNPIILlkoxdpKF65eHwLYaNSY7gCTl9AubXkcMMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/moodboards/index.vue?macro=true";
import { default as personal_45areaCNZl4AaRhMlgueZMlJIxI_S3enZKYjp1yO_lOvBy9BwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area.vue?macro=true";
import { default as indexQkEdiyGjXXA7oOfQ7MYLSsuaWJ0Ds5VAsJ8PzgyBrDAMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/atelier/index.vue?macro=true";
import { default as indexqxz97qa553oSCzEqj5iOqy74M7wMQxd9bAFU6YKI4DcMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/activated/index.vue?macro=true";
import { default as indexZdwmwwa9i0jWU98sctzEw_KQtyDyR9FY8M_z_4552N6F4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/twelve/index.vue?macro=true";
import { default as indexRL2C4C8DPuoqIz_45vqKHZ_Zo1Nz20wMGG8IRXFfM5iskMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/activation/index.vue?macro=true";
import { default as index6ktO8SzEpbILLNUGVGW7jz4lkR84oaAmYXnrLmiPqA4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/bathroom/index.vue?macro=true";
import { default as _91id_93MSrNyvnugQXGfa6HgGSfUCBLybxkOXTgNrxkMcC5_45RoMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/collections/[id].vue?macro=true";
import { default as indexPuafVlhf81KTjxQTdBrfaMToNugEH5mBxJOUKMj61HIMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/professional/index.vue?macro=true";
import { default as indexD7vJ9a3Tl9T7oy_BmDT8FQBV6TXwIZLjpMfu9xA0aRYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/company/index.vue?macro=true";
import { default as index9GnmRJlI5TzhUJJpZt73zoUP7Tc_457vORjiLfE0PQZcsMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/collections/index.vue?macro=true";
import { default as indexU_bq087Xh9CTLYs2FrcbekcUoDZ4td16eOkY3Qx_45sp4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/resource/index.vue?macro=true";
import { default as index9oAlkyKtI0NKgXo1dIkFZlWA_45_T_MiQTTL1w_455eb_45tAMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/find-product/index.vue?macro=true";
import { default as indexxwOVj3ftH_450cxtX8_lrwyVCors8YzA17Z0TS5CRN9bcMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/engineering/index.vue?macro=true";
import { default as _91id_930oK3No2u0E_erkFX5O7xaFk2Bl43ktIyY9K16dQsqqQMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/style-and-mood/[id].vue?macro=true";
import { default as indexpB_45kyFdkaXHnukdMwlXjBlO9Przif0tq6vxfJDqe9VUMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/password-[type]/index.vue?macro=true";
import { default as index_45ugwK9bsL4n1JhvCDOzigbw9iEocyuJLLQfJ2ApwR98Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/architects-and-designers/index.vue?macro=true";
import { default as indexW8RQy8aJxYFOMEx6XJTw_NKN9g6kWXjB9YUKyF_45w5c4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signin/password-recovery/index.vue?macro=true";
import { default as index9aAARIdsqfwQprRreWOzrMxmV41x3_45ytdMGn_8kZ4mkMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/work-with-us/index.vue?macro=true";
import { default as indexpZN351hzr91wt3XeqikLBsR_45R_y20h2foUsXBu_45iFsAMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/sustainability/index.vue?macro=true";
import { default as indexCFDBQVisiQLk1FkcS5WJ9yIun22RQOb2R29mP0f8dFUMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/cookie-policy/index.vue?macro=true";
import { default as indexkjcWuVPKRpXQyZpyUS0M9imhiqVbitgdgH7guitFdSwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/terms-of-sale/index.vue?macro=true";
import { default as indexi_45zOJTK32DZzssj7jvIaT7RLklPWYNNz2q6H6DLfMCwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/porcelain-tiles/index.vue?macro=true";
import { default as index1p27XBpBwkWhFYDBmiqntN_Lk7YpKiESSoMNfot6B_45gMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/pools-and-wellness/index.vue?macro=true";
import { default as indexDcWmnGBynOw55kfhdcat9KnEQFwxnMUjws2L0pCjtn4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/privacy-policy/index.vue?macro=true";
import { default as index3_45tyGFdkaVvqnMGLIF5RFK6wWahQO1KJUacuWQKLZrgMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/whistleblowing/index.vue?macro=true";
import { default as indexmW6OQrFq4hbJKJuW9zIfJdEkACTeErv50vn51z_4531OYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/floor-and-wall-tiles/index.vue?macro=true";
import { default as indexwBAOgLbaBT9puXJsUM8vduXahxmGbqfmkuxdXD9HAa4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/tables-and-furniture/index.vue?macro=true";
import { default as index2wkRuACQc6Ltyu3RmqdGv0YuBPQz_bnhOD1I_453Q21jwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/outdoor-and-gardening/index.vue?macro=true";
import { default as _91id_93Sh2PsUGycKnfC1K0_CU9CBlvnXPD4_45tE_jfTSrzfJBwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/mirage-project-point/[id].vue?macro=true";
import { default as indexO0QYgqgBDfgtpHHL5gUd_45d7TBo2EcYBQ5E2dAmpcKk8Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/mirage-project-point/index.vue?macro=true";
import { default as index_bQJWYYhKiK9E50qxoA_45XK_45AlLRaja2_CAX0y3v8W4MMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/copyright-disclaimer/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/html/mirage/frontend/pages/index.vue")
  },
  {
    name: "country-locale-404",
    path: "/:country()/:locale()/404",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/404.vue")
  },
  {
    name: "country-locale",
    path: "/:country()/:locale()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/index.vue")
  },
  {
    name: "country-locale-support",
    path: "/:country()/:locale()/support",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support.vue"),
    children: [
  {
    name: "country-locale-support-private-commercial-consultancy",
    path: "private-commercial-consultancy",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-commercial-consultancy/index.vue")
  },
  {
    name: "country-locale-support-professional-hr-images-request",
    path: "professional-hr-images-request",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-hr-images-request/index.vue")
  },
  {
    name: "country-locale-support-private-product-info-and-solutions",
    path: "private-product-info-and-solutions",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-product-info-and-solutions/index.vue")
  },
  {
    name: "country-locale-support-professional-commercial-consultancy",
    path: "professional-commercial-consultancy",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-commercial-consultancy/index.vue")
  },
  {
    name: "country-locale-support-professional-product-info-and-solutions",
    path: "professional-product-info-and-solutions",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-product-info-and-solutions/index.vue")
  },
  {
    name: "country-locale-support-private-after-sales-and-installation-technical-assistance",
    path: "private-after-sales-and-installation-technical-assistance",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-after-sales-and-installation-technical-assistance/index.vue")
  },
  {
    name: "country-locale-support-professional-after-sales-and-installation-technical-assistance",
    path: "professional-after-sales-and-installation-technical-assistance",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-after-sales-and-installation-technical-assistance/index.vue")
  }
]
  },
  {
    name: "country-locale-demo-ease",
    path: "/:country()/:locale()/demo/ease",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/ease.vue")
  },
  {
    name: "country-locale-faq",
    path: "/:country()/:locale()/faq",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/faq/index.vue")
  },
  {
    name: "country-locale-news-id",
    path: "/:country()/:locale()/news/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/news/[id].vue")
  },
  {
    name: "country-locale-resources",
    path: "/:country()/:locale()/resources",
    meta: resourcesH7DrQf_gAbiKzICxwMc46xgxiW9P6ytzDaDyELqktYgMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources.vue"),
    children: [
  {
    name: "country-locale-resources-handbooks",
    path: "handbooks",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/handbooks/index.vue")
  },
  {
    name: "country-locale-resources-monographs",
    path: "monographs",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/monographs/index.vue")
  },
  {
    name: "country-locale-resources-collections",
    path: "collections",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/collections/index.vue")
  },
  {
    name: "country-locale-resources-certifications",
    path: "certifications",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/certifications/index.vue")
  }
]
  },
  {
    name: "country-locale-demo-block",
    path: "/:country()/:locale()/demo/block",
    meta: block7sf_45UI_anSgGhafkG6ncoF4Rm5FdEjecaH5vMABNbAoMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block.vue"),
    children: [
  {
    name: "country-locale-demo-block-form",
    path: "form",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/form/index.vue")
  },
  {
    name: "country-locale-demo-block-grid",
    path: "grid",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/grid/index.vue")
  },
  {
    name: "country-locale-demo-block-text",
    path: "text",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/text/index.vue")
  },
  {
    name: "country-locale-demo-block-media",
    path: "media",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/media/index.vue")
  },
  {
    name: "country-locale-demo-block-divider",
    path: "divider",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/divider/index.vue")
  },
  {
    name: "country-locale-demo-block-toolbar",
    path: "toolbar",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/toolbar/index.vue")
  },
  {
    name: "country-locale-demo-block-carousel",
    path: "carousel",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/carousel/index.vue")
  },
  {
    name: "country-locale-demo-block-launcher",
    path: "launcher",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/launcher/index.vue")
  }
]
  },
  {
    name: "country-locale-demo",
    path: "/:country()/:locale()/demo",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/index.vue")
  },
  {
    name: "country-locale-demo-surfer",
    path: "/:country()/:locale()/demo/surfer",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/surfer.vue")
  },
  {
    name: "country-locale-press",
    path: "/:country()/:locale()/press",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/press/index.vue")
  },
  {
    name: "country-locale-trends-id",
    path: "/:country()/:locale()/trends/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/trends/[id].vue")
  },
  {
    name: "country-locale-demo-geolist",
    path: "/:country()/:locale()/demo/geolist",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/geolist.vue")
  },
  {
    name: "country-locale-search",
    path: "/:country()/:locale()/search",
    meta: indexV8CsTvtJcrZWxerpFgD2j7hNGd_45xspm93UZCS3umjQoMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/search/index.vue")
  },
  {
    name: "country-locale-signin",
    path: "/:country()/:locale()/signin",
    meta: indexgg8g4fcGdkaU0HBly181roiQzZdCwMeV1O_455iFEV7FMMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signin/index.vue")
  },
  {
    name: "country-locale-signup",
    path: "/:country()/:locale()/signup",
    meta: indexUBAUGbtYA3bjUvvTJPmke_45ah6uH9kzbSQaiMA0E3444Meta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/index.vue")
  },
  {
    name: "country-locale-account",
    path: "/:country()/:locale()/account",
    meta: index5ZPhV3kO0Bxp3pB6DEXXG_KGapVWMYa7L84UB7dV0cQMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/index.vue")
  },
  {
    name: "country-locale-demo-composer",
    path: "/:country()/:locale()/demo/composer",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/composer.vue")
  },
  {
    name: "country-locale-projects-id",
    path: "/:country()/:locale()/projects/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/projects/[id].vue")
  },
  {
    name: "country-locale-contacts",
    path: "/:country()/:locale()/contacts",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/contacts/index.vue")
  },
  {
    name: "country-locale-account-profile",
    path: "/:country()/:locale()/account/profile",
    meta: profileMFnNXkKc8_45eEEJ5Elg6zEBHEOLT2MkBBZOeIW52hJoIMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile.vue"),
    children: [
  {
    name: "country-locale-account-profile-info",
    path: "info",
    meta: index6IDTHWMH1m0pbteHwYudDKrmUT16c1iU52jI_451jypMEMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile/info/index.vue")
  },
  {
    name: "country-locale-account-profile-change-password",
    path: "change-password",
    meta: indexTwHPcCzCKvTwN2qysmZi_G2v_45_j6rEsxcd4MQw6_7msMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile/change-password/index.vue")
  }
]
  },
  {
    name: "country-locale-moodboards-id",
    path: "/:country()/:locale()/moodboards/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/moodboards/[id].vue")
  },
  {
    name: "country-locale-demo-carousel-pin",
    path: "/:country()/:locale()/demo/carousel-pin",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/carousel-pin.vue")
  },
  {
    name: "country-locale-demo-media-options",
    path: "/:country()/:locale()/demo/media-options",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/media-options.vue")
  },
  {
    name: "country-locale-design-stories-id",
    path: "/:country()/:locale()/design-stories/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/design-stories/[id].vue")
  },
  {
    name: "country-locale-products-id",
    path: "/:country()/:locale()/products/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/[id]/index.vue")
  },
  {
    name: "country-locale-store-locator",
    path: "/:country()/:locale()/store-locator",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/store-locator/index.vue")
  },
  {
    name: "country-locale-projects-and-stories",
    path: "/:country()/:locale()/projects-and-stories",
    meta: projects_45and_45storiesqktrwUtYxe5UVi5w4gsyrICBU8x5uazhHG5a4vPq3NsMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories.vue"),
    children: [
  {
    name: "country-locale-projects-and-stories-news",
    path: "news",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories/news/index.vue")
  },
  {
    name: "country-locale-projects-and-stories-trends",
    path: "trends",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories/trends/index.vue")
  },
  {
    name: "country-locale-projects-and-stories-projects",
    path: "projects",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories/projects/index.vue")
  },
  {
    name: "country-locale-projects-and-stories-design-stories",
    path: "design-stories",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/projects-and-stories/design-stories/index.vue")
  }
]
  },
  {
    name: "country-locale-signup-private",
    path: "/:country()/:locale()/signup/private",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/private/index.vue")
  },
  {
    name: "country-locale-account-personal-area",
    path: "/:country()/:locale()/account/personal-area",
    meta: personal_45areaCNZl4AaRhMlgueZMlJIxI_S3enZKYjp1yO_lOvBy9BwMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area.vue"),
    children: [
  {
    name: "country-locale-account-personal-area-favourites",
    path: "favourites",
    meta: indexQWk4q_45YHSCxHCSOPhHWTBCaIIdYk6A9iSJSvV5LKFpwMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/favourites/index.vue")
  },
  {
    name: "country-locale-account-personal-area-hires-imgs",
    path: "hires-imgs",
    meta: index5b2s_jJgQVw7X0C_2vkfupEc_A3_45mCHs1erhOi6W73IMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/hires-imgs/index.vue")
  },
  {
    name: "country-locale-account-personal-area-moodboards",
    path: "moodboards",
    meta: indexQnxvIuNPIILlkoxdpKF65eHwLYaNSY7gCTl9AubXkcMMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/moodboards/index.vue")
  }
]
  },
  {
    name: "country-locale-products-atelier",
    path: "/:country()/:locale()/products/atelier",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/atelier/index.vue")
  },
  {
    name: "country-locale-signup-activated",
    path: "/:country()/:locale()/signup/activated",
    meta: indexqxz97qa553oSCzEqj5iOqy74M7wMQxd9bAFU6YKI4DcMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/activated/index.vue")
  },
  {
    name: "country-locale-solutions-twelve",
    path: "/:country()/:locale()/solutions/twelve",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/twelve/index.vue")
  },
  {
    name: "country-locale-signup-activation",
    path: "/:country()/:locale()/signup/activation",
    meta: indexRL2C4C8DPuoqIz_45vqKHZ_Zo1Nz20wMGG8IRXFfM5iskMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/activation/index.vue")
  },
  {
    name: "country-locale-solutions-bathroom",
    path: "/:country()/:locale()/solutions/bathroom",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/bathroom/index.vue")
  },
  {
    name: "country-locale-products-collections-id",
    path: "/:country()/:locale()/products/collections/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/collections/[id].vue")
  },
  {
    name: "country-locale-signup-professional",
    path: "/:country()/:locale()/signup/professional",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/professional/index.vue")
  },
  {
    name: "country-locale-mirage-world-company",
    path: "/:country()/:locale()/mirage-world/company",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/company/index.vue")
  },
  {
    name: "country-locale-products-collections",
    path: "/:country()/:locale()/products/collections",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/collections/index.vue")
  },
  {
    name: "country-locale-mirage-world-resource",
    path: "/:country()/:locale()/mirage-world/resource",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/resource/index.vue")
  },
  {
    name: "country-locale-products-find-product",
    path: "/:country()/:locale()/products/find-product",
    meta: index9oAlkyKtI0NKgXo1dIkFZlWA_45_T_MiQTTL1w_455eb_45tAMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/find-product/index.vue")
  },
  {
    name: "country-locale-solutions-engineering",
    path: "/:country()/:locale()/solutions/engineering",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/engineering/index.vue")
  },
  {
    name: "country-locale-products-style-and-mood-id",
    path: "/:country()/:locale()/products/style-and-mood/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/style-and-mood/[id].vue")
  },
  {
    name: "country-locale-account-password-type",
    path: "/:country()/:locale()/account/password-:type()",
    meta: indexpB_45kyFdkaXHnukdMwlXjBlO9Przif0tq6vxfJDqe9VUMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/password-[type]/index.vue")
  },
  {
    name: "country-locale-architects-and-designers",
    path: "/:country()/:locale()/architects-and-designers",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/architects-and-designers/index.vue")
  },
  {
    name: "country-locale-signin-password-recovery",
    path: "/:country()/:locale()/signin/password-recovery",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signin/password-recovery/index.vue")
  },
  {
    name: "country-locale-mirage-world-work-with-us",
    path: "/:country()/:locale()/mirage-world/work-with-us",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/work-with-us/index.vue")
  },
  {
    name: "country-locale-mirage-world-sustainability",
    path: "/:country()/:locale()/mirage-world/sustainability",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/sustainability/index.vue")
  },
  {
    name: "country-locale-info-and-legal-cookie-policy",
    path: "/:country()/:locale()/info-and-legal/cookie-policy",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/cookie-policy/index.vue")
  },
  {
    name: "country-locale-info-and-legal-terms-of-sale",
    path: "/:country()/:locale()/info-and-legal/terms-of-sale",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/terms-of-sale/index.vue")
  },
  {
    name: "country-locale-mirage-world-porcelain-tiles",
    path: "/:country()/:locale()/mirage-world/porcelain-tiles",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/porcelain-tiles/index.vue")
  },
  {
    name: "country-locale-solutions-pools-and-wellness",
    path: "/:country()/:locale()/solutions/pools-and-wellness",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/pools-and-wellness/index.vue")
  },
  {
    name: "country-locale-info-and-legal-privacy-policy",
    path: "/:country()/:locale()/info-and-legal/privacy-policy",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/privacy-policy/index.vue")
  },
  {
    name: "country-locale-info-and-legal-whistleblowing",
    path: "/:country()/:locale()/info-and-legal/whistleblowing",
    meta: index3_45tyGFdkaVvqnMGLIF5RFK6wWahQO1KJUacuWQKLZrgMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/whistleblowing/index.vue")
  },
  {
    name: "country-locale-solutions-floor-and-wall-tiles",
    path: "/:country()/:locale()/solutions/floor-and-wall-tiles",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/floor-and-wall-tiles/index.vue")
  },
  {
    name: "country-locale-solutions-tables-and-furniture",
    path: "/:country()/:locale()/solutions/tables-and-furniture",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/tables-and-furniture/index.vue")
  },
  {
    name: "country-locale-solutions-outdoor-and-gardening",
    path: "/:country()/:locale()/solutions/outdoor-and-gardening",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/outdoor-and-gardening/index.vue")
  },
  {
    name: "country-locale-mirage-world-mirage-project-point-id",
    path: "/:country()/:locale()/mirage-world/mirage-project-point/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/mirage-project-point/[id].vue")
  },
  {
    name: "country-locale-mirage-world-mirage-project-point",
    path: "/:country()/:locale()/mirage-world/mirage-project-point",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/mirage-project-point/index.vue")
  },
  {
    name: "country-locale-info-and-legal-copyright-disclaimer",
    path: "/:country()/:locale()/info-and-legal/copyright-disclaimer",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/copyright-disclaimer/index.vue")
  }
]
<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-account-activated"
        data-skin="white"
        class="relative z-50"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div
            class="modal-backdrop !pointer-events-none"
            aria-hidden="true"
          ></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div
          class="pointer-events-none overflow-clip fixed inset-0 flex justify-end w-full h-full"
        >
          <!-- Container to center the panel -->
          <div class="flex items-stretch justify-end w-full h-full max-h-full">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                data-skin="white"
                class="pointer-events-auto overflow-y-auto flex flex-col gap-y-2xl w-[calc(100vw-60px)] max-w-[570px] h-full max-h-full bg-skin-base dark:bg-skin-muted p-3xl"
              >
                <section>
                  <txt-heading
                    size="regular"
                    :title="$t.titles.account_activated"
                    class="w-full"
                    :text="$t.texts.account_activated"
                  />
                </section>

                <section
                  class="flex flex-col items-stretch w-full col-span-full laptop:col-start-7 laptop:col-end-13 gap-2xl"
                >
                  <wrapper-split-text reveal="chars">
                    <div
                      class="text-heading-6"
                      v-html="$t.labels.login_now"
                    ></div>
                  </wrapper-split-text>
                  <form-signin
                    class="w-full"
                    :email="email"
                    :referral="referral"
                  />

                  <btn
                    ref="tpl_btnSkip"
                    :title="$t.labels.continue_no_login"
                    class="text-button-small text-skin-muted hover:text-skin-base underline whitespace-nowrap self-end transition-colors duration-300 ease-in-out"
                    @click="emit('close')"
                    >{{ $t.labels.continue_no_login }}</btn
                  >
                </section>
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  email: {
    type: String,
    required: false,
  },
  referral: {
    type: String,
    required: false,
    default: "https://google.com",
  },
});
const route = useRoute();
const emit = defineEmits(["close"]);
const tpl_btnSkip = templateRef("tpl_btnSkip");

watch(
  () => route.path,
  (newVal, oldVal) => {
    emit("close");
  }
);

useSafeMountedEl(tpl_btnSkip, () => {
  tpl_btnSkip.value.$el.blur();
});
</script>

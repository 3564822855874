import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/var/www/html/mirage/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/var/www/html/mirage/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/var/www/html/mirage/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8 from "/var/www/html/mirage/frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/var/www/html/mirage/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/var/www/html/mirage/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/var/www/html/mirage/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/var/www/html/mirage/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/var/www/html/mirage/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/var/www/html/mirage/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/var/www/html/mirage/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/var/www/html/mirage/frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/var/www/html/mirage/frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import google_recaptcha_3eLQazCSqDGZ_eSLnukyetOWDUMTzb3l_lthO4JzEMs from "/var/www/html/mirage/frontend/plugins/google-recaptcha.ts";
import gsap_client_4O_KCpEZFi9No8UfLrmvzlvCUnQWraJAKw3RsIfcXlg from "/var/www/html/mirage/frontend/plugins/gsap.client.js";
import init_88c3WPPAtwGgD6HzSCraVh_TmDcESbBDkLCROGJkFvI from "/var/www/html/mirage/frontend/plugins/init.js";
import surfer_L6tAoVkwK4OqW1_dEIPUA0FxVooHtUgDzOQkjmw3_VU from "/var/www/html/mirage/frontend/plugins/surfer.js";
import swiper_client_JZJ7Ca7NbawPpkhdB99T08ptHmHQII_TP5_9OXFLdLo from "/var/www/html/mirage/frontend/plugins/swiper.client.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  google_recaptcha_3eLQazCSqDGZ_eSLnukyetOWDUMTzb3l_lthO4JzEMs,
  gsap_client_4O_KCpEZFi9No8UfLrmvzlvCUnQWraJAKw3RsIfcXlg,
  init_88c3WPPAtwGgD6HzSCraVh_TmDcESbBDkLCROGJkFvI,
  surfer_L6tAoVkwK4OqW1_dEIPUA0FxVooHtUgDzOQkjmw3_VU,
  swiper_client_JZJ7Ca7NbawPpkhdB99T08ptHmHQII_TP5_9OXFLdLo
]
import { defineStore } from "pinia";

export const useRootStore = defineStore("root", () => {
  const _appHeaderBtnMirageY = ref(0);
  const _appHeaderOnColor = ref(false);
  const _appHeaderIsHidden = ref(false);
  const _ui = ref([]);
  const _copyrightDisclaimer = ref();
  const _needsScrollReset = ref();
  const _bathroomIntroAnimation = ref(false);
  const _prevRoute = ref(null);
  const appHeaderIsHidden = computed(() => _appHeaderIsHidden.value);
  const appHeaderBtnMirageY = computed(() => _appHeaderBtnMirageY.value);
  const appHeaderOnColor = computed(() => _appHeaderOnColor.value);
  const copyrightDisclaimer = computed(() => _copyrightDisclaimer.value);
  const needsScrollReset = computed(() => _needsScrollReset.value);
  const bathroomIntroAnimation = computed(() => _bathroomIntroAnimation.value);
  const prevRoute = computed(() => _prevRoute.value);
  const ui = computed(() => _ui.value);

  const excludeQueryParams = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "gclid",
    "fbclid",
    "msclkid",
    "mc_eid",
    "mc_cid",
    "referral",
    "email",
    "token",
    "account-activated",
  ];

  const route = useRoute();

  function setAppHeaderBtnMirageY(y) {
    _appHeaderBtnMirageY.value = y;
  }

  function setAppHeaderOnColor(boo = false) {
    _appHeaderOnColor.value = boo;
  }

  function setAppHeaderIsHidden(boo = false) {
    _appHeaderIsHidden.value = boo;
  }

  function setNeedsScrollReset(value) {
    _needsScrollReset.value = value;
  }

  function setBathroomIntroAnimation(value) {
    _bathroomIntroAnimation.value = value;
  }

  function setPrevRoute(route) {
    _prevRoute.value = route;
  }

  async function loadUI() {
    const { data } = await useFetch(`/api/ui?lng=${route.params.locale}`);
    _ui.value = data.value;

    return _ui.value;
  }

  async function loadCopyrightDisclaimer(url) {
    const data = await $fetch(
      `/api/page_copyright_disclaimer?lng=${route.params.locale}`
    );
    _copyrightDisclaimer.value =
      data.body.data.wrappers[0].data.tabs[0].blocks[0].data.body.html;
    return _copyrightDisclaimer.value;
  }

  const _downloadsCopyrightDisclaimerCookie = useCookie(
    "mrg_downloads-copyright-disclaimer",
    {
      maxAge: 60 * 60 * 24 * 365,
      path: "/",
    }
  );

  function setDownloadsCopyrightDisclaimerCookie(value) {
    _downloadsCopyrightDisclaimerCookie.value = value;
  }

  return {
    appHeaderIsHidden,
    appHeaderOnColor,
    appHeaderBtnMirageY,
    ui,
    copyrightDisclaimer,
    needsScrollReset,
    bathroomIntroAnimation,
    prevRoute,
    setNeedsScrollReset,
    setAppHeaderIsHidden,
    setAppHeaderBtnMirageY,
    setAppHeaderOnColor,
    setPrevRoute,
    loadUI,
    loadCopyrightDisclaimer,
    setDownloadsCopyrightDisclaimerCookie,
    setBathroomIntroAnimation,
    downloadsCopyrightDisclaimerCookie: computed(
      () => _downloadsCopyrightDisclaimerCookie.value
    ),
    cloudinary: {
      cloud: "dhgy28eyh",
      folder: "mirage-staging",
    },
    thron: {
      clientId: "mirage",
      contentId: "d61ac3be-e7ab-496b-a34f-695e7724f249",
      // pkey: "2am5n6",
      pkey: "9pkhty",
    },
    excludeQueryParams,
  };
});
